import { json } from "@remix-run/node";
import { getOrders } from "../controllers/orders.server";
import { Link, useLoaderData } from "@remix-run/react";
import "../styles/orders/Orders.css";
import { getSession } from "../sessions";
import Footer from "../components/Footer";
import dayjs from "dayjs";

export const loader = async ({ request, params }) => {
  const session = await getSession(request.headers.get("Cookie"));
  const { email } = session.data;

  if (!email) {
    return json({ orders: [] });
  }

  const orders = await getOrders(email);

  return json({ orders });
};

export default function Index() {
  const { orders } = useLoaderData();

  if (!orders || orders.length === 0) {
    return (
      <section className="orders__section">
        <Footer />
      </section>
    );
  }

  return (
    <section className="orders__section">
      <h1 className="title">Orders</h1>
      <h3 className="email">
        <span>Email: </span> {orders[0].email}
      </h3>

      <div className="orders__container">
        {orders.length > 0 ? (
          orders.map((order) => (
            <div key={order._id} className="orders__card">
              <div className="content">
                {/* image */}
                <div className="image"></div>

                <div className="text">
                  <p>#{order.orderId}</p>
                  <h4>
                    {order.products[0].name + " " + order.products[0].variant}
                  </h4>
                  <span>Order placed</span>
                  <h5>{dayjs(order.orderTime).format("DD MMM YYYY, HH:mm")}</h5>
                </div>
              </div>

              <Link to={`/orders/${order._id}`}>
                <button className="shopify__btn">View Order</button>
              </Link>
            </div>
          ))
        ) : (
          <div className="no-orders">
            <h2>No Orders</h2>
            <p>There are no orders to display</p>
          </div>
        )}
      </div>

      <Footer />
    </section>
  );
}
